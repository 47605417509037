import { API_CONFIG } from './config';
import type { GenerationParams, GenerationResponse } from '../../types';
import { getPromptWithTriggerWords } from '../../utils/prompt-utils';
import { ASPECT_RATIO_DIMENSIONS } from '../../constants';
import { validateStyle } from '../../constants/styles';

// Add delay utility function
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Generation state constants
const GENERATION_STATES = {
  PREPARING: 'Preparing your canvas...',
  PROCESSING_PROMPT: 'Processing your prompt...',
  GENERATING: 'Generating your masterpiece...',
  DETAILING: 'Adding details...',
  REFINING: 'Refining the artwork...',
  FINALIZING: 'Finalizing...',
  VERIFYING: 'Verifying images...'
} as const;

export async function generateImage(params: GenerationParams): Promise<GenerationResponse> {
  try {
    const processedPrompt = getPromptWithTriggerWords(params.prompt, params.loraModels);
    const dimensions = ASPECT_RATIO_DIMENSIONS[params.aspectRatio];

    // Extract active styles (non "No Style" values)
    const activeStyles = params.styles ? 
      Object.values(params.styles).filter(style => style !== 'No Style') : 
      [];

    // Create the style inputs
    const styleInputs = {
      [API_CONFIG.INPUT_IDS.STYLE_1]: { 
        title: "Style 1", 
        value: activeStyles[0] || 'No Style'
      },
      [API_CONFIG.INPUT_IDS.STYLE_2]: { 
        title: "Style 2", 
        value: activeStyles[1] || 'No Style'
      },
      [API_CONFIG.INPUT_IDS.STYLE_3]: { 
        title: "Style 3", 
        value: activeStyles[2] || 'No Style'
      },
      [API_CONFIG.INPUT_IDS.STYLE_4]: { 
        title: "Style 4", 
        value: activeStyles[3] || 'No Style'
      }
    };

    const response = await fetch(`${API_CONFIG.BASE_URL}/run_task`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${API_CONFIG.API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        inputs: {
          [API_CONFIG.INPUT_IDS.SEED]: { 
            title: "Seed", 
            value: params.seed 
          },
          [API_CONFIG.INPUT_IDS.PROMPT]: { 
            title: "Prompt", 
            value: processedPrompt 
          },
          [API_CONFIG.INPUT_IDS.ALMARAI]: { 
            title: "Almarai Product Model strength",
            value: params.loraModels.find(m => m.id === 'almarai')?.value || 0 
          },
          [API_CONFIG.INPUT_IDS.NAJDI]: { 
            title: "Najdi Architecture strength",
            value: params.loraModels.find(m => m.id === 'najdi')?.value || 0 
          },
          [API_CONFIG.INPUT_IDS.OMANI_ARCH]: { 
            title: "Traditional Omani Architecture Strength",
            value: params.loraModels.find(m => m.id === 'omani-arch')?.value || 0 
          },
          [API_CONFIG.INPUT_IDS.WIDTH]: { 
            title: "Width", 
            value: dimensions.width 
          },
          [API_CONFIG.INPUT_IDS.HEIGHT]: { 
            title: "Height", 
            value: dimensions.height 
          },
          [API_CONFIG.INPUT_IDS.BATCH_SIZE]: {
            title: "Batch_size",
            value: params.batchSize
          },
          ...styleInputs
        }
      })
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || `API error: ${response.status}`);
    }

    const data = await response.json();
    
    if (!data.task_id) {
      throw new Error('Invalid response: missing task ID');
    }

    return {
      task_id: data.task_id,
      status: data.status || 'IN_QUEUE',
      image_urls: [],
      completed_steps: 0,
      estimated_steps: data.estimated_steps || 20,
      prompt: processedPrompt,
      seed: params.seed,
      styles: params.styles,
      progress_message: GENERATION_STATES.PREPARING
    };
  } catch (error) {
    console.error('Generation error:', error);
    throw error instanceof Error ? error : new Error('Failed to generate image');
  }
}

export async function getTaskStatus(taskId: string): Promise<GenerationResponse> {
  try {
    const response = await fetch(`${API_CONFIG.BASE_URL}/task_status/${taskId}`, {
      headers: {
        'Authorization': `Bearer ${API_CONFIG.API_KEY}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || `API error: ${response.status}`);
    }

    const data = await response.json();
    
    let status = data.status || 'IN_QUEUE';
    if (status === 'CREATED') status = 'IN_QUEUE';

    // Only mark as completed if we have all the expected images
    const expectedImageCount = data.batch_size || 1;
    const hasAllImages = data.image_urls?.length === expectedImageCount;
    
    // Calculate progress and set appropriate message
    const progress = data.completed_steps ? 
      Math.round((data.completed_steps / (data.estimated_steps || 20)) * 100) : 0;

    let progressMessage = GENERATION_STATES.PROCESSING_PROMPT;

    // Determine the progress message based on completion percentage
    if (progress > 0 && progress <= 20) {
      progressMessage = GENERATION_STATES.PROCESSING_PROMPT;
      // Add a small delay at the start
      await delay(500);
    } else if (progress > 20 && progress <= 40) {
      progressMessage = GENERATION_STATES.GENERATING;
      await delay(300);
    } else if (progress > 40 && progress <= 60) {
      progressMessage = GENERATION_STATES.DETAILING;
      await delay(300);
    } else if (progress > 60 && progress <= 80) {
      progressMessage = GENERATION_STATES.REFINING;
      await delay(300);
    } else if (progress > 80 && progress < 100) {
      progressMessage = GENERATION_STATES.FINALIZING;
      await delay(300);
    }

    // If we have partial images, update the progress message
    if (data.image_urls?.length > 0 && !hasAllImages) {
      progressMessage = `Generated ${data.image_urls.length}/${expectedImageCount} images`;
      await delay(500);
    }

    // If we have all images, add a verification delay
    if (hasAllImages) {
      progressMessage = GENERATION_STATES.VERIFYING;
      
      // Add a verification delay
      await delay(2000);
      
      // Verify the images are still available with a second request
      const verificationResponse = await fetch(`${API_CONFIG.BASE_URL}/task_status/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${API_CONFIG.API_KEY}`,
        },
      });
      
      if (verificationResponse.ok) {
        const verificationData = await verificationResponse.json();
        if (verificationData.image_urls?.length === expectedImageCount) {
          status = 'COMPLETED';
          // Add a final delay before completing
          await delay(500);
        } else {
          // If verification fails, keep as in progress
          status = 'IN_PROGRESS';
          progressMessage = GENERATION_STATES.VERIFYING;
        }
      }
    }

    return {
      task_id: taskId,
      status,
      image_urls: data.image_urls || [],
      completed_steps: data.completed_steps || 0,
      estimated_steps: data.estimated_steps || 20,
      error: data.error,
      delay_time: data.delay_time,
      execution_time: data.execution_time,
      cost: data.cost,
      seed: data.seed,
      prompt: data.prompt,
      styles: data.styles,
      progress_message: progressMessage
    };
  } catch (error) {
    console.error('Status check error:', error);
    throw error instanceof Error ? error : new Error('Failed to check generation status');
  }
}