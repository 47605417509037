import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  serverTimestamp,
  enableIndexedDbPersistence
} from 'firebase/firestore';
import { db } from './config';
import { uploadGeneratedImage } from './storage';
import type { TaskStatus, LoraModel, StyleOptions } from '../types';
import { COLLECTIONS } from './collections';

// Initialize Firestore with persistence enabled
try {
  enableIndexedDbPersistence(db, {
    synchronizeTabs: true
  }).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence enabled in first tab only');
    } else if (err.code === 'unimplemented') {
      console.warn('Browser doesn\'t support persistence');
    }
  });
} catch (err) {
  console.warn('Error initializing persistence:', err);
}

export interface GenerationDocument {
  userId: string;
  status: string;
  prompt: string;
  imageUrls: string[];
  firebaseImageUrls: string[];
  completedSteps: number;
  estimatedSteps: number;
  executionTime: number | null;
  seed: number;
  createdAt: any;
  loraModels: LoraModel[];
  styles: StyleOptions;
  aspectRatio: string;
}

function sanitizeGenerationData(data: any): Partial<GenerationDocument> {
  const sanitized = {
    userId: data.userId,
    status: data.status || 'UNKNOWN',
    prompt: data.prompt || '',
    imageUrls: Array.isArray(data.imageUrls) ? data.imageUrls : [],
    firebaseImageUrls: Array.isArray(data.firebaseImageUrls) ? data.firebaseImageUrls : [],
    completedSteps: typeof data.completedSteps === 'number' ? data.completedSteps : 0,
    estimatedSteps: typeof data.estimatedSteps === 'number' ? data.estimatedSteps : 0,
    executionTime: typeof data.executionTime === 'number' ? data.executionTime : null,
    seed: typeof data.seed === 'number' ? data.seed : 0,
    createdAt: data.createdAt || serverTimestamp(),
    loraModels: Array.isArray(data.loraModels) ? data.loraModels.map(model => ({
      id: model.id,
      title: model.title,
      value: Number(model.value) || 0,
      triggerWord: model.triggerWord || ''
    })) : [],
    styles: data.styles || {
      style1: 'No Style',
      style2: 'No Style',
      style3: 'No Style',
      style4: 'No Style'
    },
    aspectRatio: data.aspectRatio || '1:1'
  };

  // Remove undefined values
  Object.keys(sanitized).forEach(key => {
    if (sanitized[key] === undefined) {
      delete sanitized[key];
    }
  });

  return sanitized;
}

export async function saveGeneration(
  userId: string, 
  generation: TaskStatus,
  loraModels: LoraModel[] = [],
  styles: StyleOptions = {
    style1: 'No Style',
    style2: 'No Style',
    style3: 'No Style',
    style4: 'No Style'
  },
  aspectRatio: string = '1:1'
): Promise<void> {
  try {
    if (!userId) throw new Error('User ID is required');

    const firebaseImageUrls = generation.image_urls?.length ? 
      await Promise.all(
        generation.image_urls.map(url => uploadGeneratedImage(userId, url, generation.task_id))
      ) : [];

    const rawData = {
      userId,
      status: generation.status,
      prompt: generation.prompt || '',
      imageUrls: generation.image_urls || [],
      firebaseImageUrls,
      completedSteps: generation.completed_steps || 0,
      estimatedSteps: generation.estimated_steps || 0,
      executionTime: generation.execution_time || null,
      seed: generation.seed || 0,
      createdAt: serverTimestamp(),
      loraModels: loraModels.map(model => ({
        id: model.id,
        title: model.title,
        value: Number(model.value) || 0,
        triggerWord: model.triggerWord
      })),
      styles,
      aspectRatio
    };

    const sanitizedData = sanitizeGenerationData(rawData);
    const generationsRef = collection(db, COLLECTIONS.GENERATIONS);
    await addDoc(generationsRef, sanitizedData);
  } catch (error) {
    console.error('Error saving generation:', error);
    throw error;
  }
}

export async function getUserGenerations(userId: string): Promise<TaskStatus[]> {
  try {
    if (!userId) {
      console.warn('No user ID provided for getUserGenerations');
      return [];
    }

    const generationsRef = collection(db, COLLECTIONS.GENERATIONS);
    const q = query(
      generationsRef,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => {
      const data = doc.data();
      
      return {
        task_id: doc.id,
        status: data.status,
        prompt: data.prompt,
        image_urls: data.firebaseImageUrls?.length ? 
          data.firebaseImageUrls : 
          data.imageUrls,
        completed_steps: data.completedSteps,
        estimated_steps: data.estimatedSteps,
        execution_time: data.executionTime,
        seed: data.seed,
        loraModels: data.loraModels,
        styles: data.styles,
        aspectRatio: data.aspectRatio
      };
    });
  } catch (error) {
    console.error('Error loading generations:', error);
    return [];
  }
}