import React, { useState, useEffect } from 'react';
import { Header } from './Header';
import { GenerationForm } from './GenerationForm';
import { ImageDisplay } from './ImageDisplay';
import { ImageHistory } from './ImageHistory';
import { Gallery } from './Gallery';
import { generateImage, getTaskStatus } from '../lib/api/instasd/service';
import { getPromptWithTriggerWords } from '../lib/utils/prompt-utils';
import { saveGeneration, getUserGenerations } from '../lib/firebase/db';
import type { LoraModel, TaskStatus, StyleOptions } from '../lib/types';
import { ASPECT_RATIOS, type AspectRatio } from '../lib/constants';
import { useAuth } from '../contexts/AuthContext';

const initialLoraModels: LoraModel[] = [
  { id: 'almarai', title: 'Almarai Product', value: 0, triggerWord: '3APF16' },
  { id: 'najdi', title: 'Najdi Architecture', value: 0, triggerWord: '1NAF18' },
  { id: 'omani-arch', title: 'Traditional Omani Architecture', value: 0, triggerWord: '1TOAF31' }
];

const POLLING_INTERVAL = 1000;

export function MainApp() {
  const { user } = useAuth();
  const [prompt, setPrompt] = useState('');
  const [seed, setSeed] = useState(Math.floor(Math.random() * 2147483647));
  const [aspectRatio, setAspectRatio] = useState<AspectRatio>(ASPECT_RATIOS.SQUARE);
  const [batchSize, setBatchSize] = useState(1);
  const [loraModels, setLoraModels] = useState<LoraModel[]>(initialLoraModels);
  const [loading, setLoading] = useState(false);
  const [currentTask, setCurrentTask] = useState<TaskStatus | null>(null);
  const [error, setError] = useState('');
  const [generationHistory, setGenerationHistory] = useState<TaskStatus[]>([]);
  const [showGallery, setShowGallery] = useState(false);
  const [styles, setStyles] = useState<StyleOptions>({
    style1: 'No Style',
    style2: 'No Style',
    style3: 'No Style',
    style4: 'No Style'
  });
  const [savedTaskIds] = useState(new Set<string>());

  useEffect(() => {
    const updatedPrompt = getPromptWithTriggerWords(prompt, loraModels);
    if (updatedPrompt !== prompt) {
      setPrompt(updatedPrompt);
    }
  }, [loraModels]);

  useEffect(() => {
    if (user) {
      getUserGenerations(user.uid)
        .then(generations => setGenerationHistory(generations))
        .catch(error => console.error('Error loading history:', error));
    }
  }, [user]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (currentTask?.task_id && loading && user) {
      intervalId = setInterval(async () => {
        try {
          const status = await getTaskStatus(currentTask.task_id);
          
          if (!loading) {
            clearInterval(intervalId);
            return;
          }

          setCurrentTask(status);

          // Check if task is complete and has all expected images
          const isComplete = status.status === 'COMPLETED';
          const hasAllImages = status.image_urls?.length === batchSize;
          const isNewTask = !savedTaskIds.has(status.task_id);

          if (isComplete && hasAllImages && isNewTask) {
            setLoading(false);
            clearInterval(intervalId);
            
            // Mark task as saved to prevent duplicate saves
            savedTaskIds.add(status.task_id);
            
            // Create a complete task status with all metadata
            const completeStatus: TaskStatus = {
              ...status,
              prompt,
              seed,
              loraModels,
              styles,
              aspectRatio
            };
            
            // Save the generation with all metadata
            await saveGeneration(user.uid, completeStatus, loraModels, styles, aspectRatio);
            
            // Update history immediately
            setGenerationHistory(prev => [completeStatus, ...prev]);
          } else if (status.status === 'FAILED' || status.error) {
            setError(status.error || 'Image generation failed. Please try again.');
            setLoading(false);
            clearInterval(intervalId);
          }
        } catch (err) {
          const message = err instanceof Error ? err.message : 'Failed to check generation status';
          console.error('Status check failed:', message);
          setError(message);
          setLoading(false);
          clearInterval(intervalId);
        }
      }, POLLING_INTERVAL);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [currentTask?.task_id, loading, user, prompt, seed, loraModels, styles, aspectRatio, batchSize, savedTaskIds]);

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt');
      return;
    }

    if (!user) {
      setError('Please sign in to generate images');
      return;
    }

    try {
      setLoading(true);
      setError('');
      setCurrentTask(null);
      
      const result = await generateImage({
        prompt,
        seed,
        loraModels,
        aspectRatio,
        styles,
        batchSize
      });

      setCurrentTask({
        ...result,
        prompt,
        seed,
        loraModels,
        styles,
        aspectRatio
      });
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to generate image';
      setError(message);
      console.error('Generation failed:', err);
      setLoading(false);
    }
  };

  const getProgress = () => {
    if (!currentTask?.completed_steps || !currentTask?.estimated_steps) return 0;
    return Math.min(
      Math.round((currentTask.completed_steps / currentTask.estimated_steps) * 100),
      100
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-rmz-darker text-gray-900 dark:text-white transition-colors">
      <Header onShowGallery={() => setShowGallery(true)} onShowCreate={() => setShowGallery(false)} />
      <main className="container mx-auto px-4 py-8">
        {showGallery ? (
          <Gallery generations={generationHistory} />
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            <div className="lg:col-span-4">
              <GenerationForm
                prompt={prompt}
                setPrompt={setPrompt}
                seed={seed}
                setSeed={setSeed}
                aspectRatio={aspectRatio}
                setAspectRatio={setAspectRatio}
                batchSize={batchSize}
                setBatchSize={setBatchSize}
                loraModels={loraModels}
                setLoraModels={setLoraModels}
                styles={styles}
                setStyles={setStyles}
                loading={loading}
                error={error}
                onGenerate={handleGenerate}
              />
            </div>

            <div className="lg:col-span-6">
              <ImageDisplay
                imageUrls={currentTask?.image_urls}
                prompt={currentTask?.prompt || prompt}
                seed={seed}
                loading={loading}
                progress={getProgress()}
                progressMessage={currentTask?.progress_message}
                loraModels={loraModels}
                styles={styles}
                aspectRatio={aspectRatio}
              />
            </div>

            <div className="lg:col-span-2">
              <ImageHistory generations={generationHistory} />
            </div>
          </div>
        )}
      </main>
    </div>
  );
}