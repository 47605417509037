import React from 'react';
import { MessageSquare, Hash, Loader2, Wand2, LayoutPanelTop, Palette, ChevronLeft, ChevronRight } from 'lucide-react';
import type { LoraModel, StyleOptions } from '../lib/types';

interface ImageDisplayProps {
  imageUrls?: string[];
  prompt?: string;
  seed?: number;
  loading?: boolean;
  progress?: number;
  progressMessage?: string;
  loraModels?: LoraModel[];
  styles?: StyleOptions;
  aspectRatio?: string;
}

export function ImageDisplay({ 
  imageUrls = [], 
  prompt, 
  seed, 
  loading, 
  progress,
  progressMessage,
  loraModels,
  styles,
  aspectRatio
}: ImageDisplayProps) {
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);

  React.useEffect(() => {
    if (imageUrls.length > 0) {
      setImageLoaded(false);
      setImageError(false);
      setCurrentImageIndex(0);
    }
  }, [imageUrls]);

  const activeBoosts = loraModels?.filter(model => model.value > 0) || [];
  const activeStyles = Object.entries(styles || {})
    .filter(([_, value]) => value !== 'No Style')
    .map(([key, value]) => {
      let displayName = '';
      switch (value) {
        case 'Photography | Cinematic':
          displayName = 'Cinematic';
          break;
        case 'Illustration | Cartoon':
          displayName = 'Cartoon';
          break;
        case '3D | Character > Cartoon Cute':
          displayName = '3D Cartoon';
          break;
        case 'Photography | Long exposure':
          displayName = 'Long Exposure';
          break;
        default:
          displayName = value;
      }
      return { id: key, value: displayName };
    });

  const clampedProgress = Math.min(progress || 0, 100);

  const handlePrevImage = () => {
    setCurrentImageIndex(prev => (prev > 0 ? prev - 1 : imageUrls.length - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex(prev => (prev < imageUrls.length - 1 ? prev + 1 : 0));
  };

  return (
    <div className="bg-white dark:bg-rmz-gray rounded-xl border border-gray-200 dark:border-rmz-gray-light overflow-hidden transition-colors">
      <div className="relative w-full h-0 pb-[100%]">
        {(loading || !imageLoaded) && (
          <div className="absolute inset-0 flex flex-col items-center justify-center gap-4 bg-gray-50 dark:bg-rmz-gray transition-colors">
            <Loader2 className="w-8 h-8 animate-spin text-rmz-primary" />
            {loading && clampedProgress !== undefined && (
              <div className="w-1/2">
                <div className="text-sm text-center text-gray-500 dark:text-gray-400 mb-2">
                  {progressMessage || 'Generating...'} {clampedProgress}%
                </div>
                <div className="w-full bg-gray-200 dark:bg-rmz-gray-light rounded-full h-2">
                  <div
                    className="bg-gradient-primary h-2 rounded-full transition-all duration-300"
                    style={{ width: `${clampedProgress}%` }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {imageError && (
          <div className="absolute inset-0 flex items-center justify-center text-red-500 dark:text-red-400 bg-gray-50 dark:bg-rmz-gray transition-colors">
            Failed to load image
          </div>
        )}
        {!imageUrls.length && !loading && (
          <div className="absolute inset-0 flex items-center justify-center text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-rmz-gray transition-colors">
            Generated image will appear here
          </div>
        )}
        {imageUrls.length > 0 && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 dark:bg-rmz-gray-light">
            <img
              src={imageUrls[currentImageIndex]}
              alt={prompt || 'Generated image'}
              className={`max-w-full max-h-full w-auto h-auto transition-opacity duration-300 ${
                imageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={() => setImageLoaded(true)}
              onError={() => setImageError(true)}
            />
            
            {/* Navigation arrows - Always show when there are multiple images */}
            {imageUrls.length > 1 && imageLoaded && (
              <>
                <button
                  onClick={handlePrevImage}
                  className="absolute left-2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <button
                  onClick={handleNextImage}
                  className="absolute right-2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
                
                {/* Image counter */}
                <div className="absolute bottom-2 left-1/2 -translate-x-1/2 px-3 py-1 rounded-full bg-black/50 text-white text-sm">
                  {currentImageIndex + 1} / {imageUrls.length}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {(prompt || seed || activeBoosts.length > 0 || activeStyles.length > 0) && (
        <div className="p-4 space-y-4 border-t border-gray-200 dark:border-rmz-gray-light">
          {prompt && (
            <div className="flex items-start gap-2 text-sm text-gray-700 dark:text-gray-300">
              <MessageSquare className="w-4 h-4 mt-1 flex-shrink-0" />
              <p className="line-clamp-2">{prompt}</p>
            </div>
          )}
          
          <div className="grid grid-cols-2 gap-4">
            {seed && (
              <div className="flex items-center gap-2 text-sm text-gray-700 dark:text-gray-300">
                <Hash className="w-4 h-4" />
                <span>Seed: {seed}</span>
              </div>
            )}
            
            {aspectRatio && (
              <div className="flex items-center gap-2 text-sm text-gray-700 dark:text-gray-300">
                <LayoutPanelTop className="w-4 h-4" />
                <span>Ratio: {aspectRatio}</span>
              </div>
            )}
          </div>

          {activeBoosts.length > 0 && (
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-white">
                <Wand2 className="w-4 h-4" />
                <span>Active Boosts</span>
              </div>
              <div className="grid grid-cols-2 gap-2">
                {activeBoosts.map(boost => (
                  <div 
                    key={boost.id}
                    className="text-xs bg-rmz-primary/10 text-rmz-primary rounded-lg px-3 py-2 flex justify-between items-center"
                  >
                    <span>{boost.title}</span>
                    <span>{boost.value.toFixed(1)}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeStyles.length > 0 && (
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-white">
                <Palette className="w-4 h-4" />
                <span>Active Styles</span>
              </div>
              <div className="grid grid-cols-2 gap-2">
                {activeStyles.map(style => (
                  <div 
                    key={style.id}
                    className="text-xs bg-rmz-primary/10 text-rmz-primary rounded-lg px-3 py-2 text-center"
                  >
                    {style.value}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}