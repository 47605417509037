import React from 'react';
import { Copy, CopyPlus, Files } from 'lucide-react';

interface BatchSizeSelectorProps {
  value: number;
  onChange: (size: number) => void;
}

export function BatchSizeSelector({ value, onChange }: BatchSizeSelectorProps) {
  const options = [
    { size: 1, icon: Copy, label: 'Single' },
    { size: 2, icon: CopyPlus, label: 'Double' },
    { size: 4, icon: Files, label: 'Quad' },
  ];

  return (
    <div className="space-y-2">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white">Frame Boost</h3>
      <div className="grid grid-cols-3 gap-2">
        {options.map(({ size, icon: Icon, label }) => (
          <button
            key={size}
            onClick={() => onChange(size)}
            className={`p-3 rounded-lg flex flex-col items-center gap-1 transition-colors ${
              value === size
                ? 'bg-rmz-primary/20 text-rmz-primary'
                : 'bg-gray-100 dark:bg-rmz-gray-light text-gray-600 dark:text-gray-400 hover:text-rmz-primary dark:hover:text-rmz-primary'
            }`}
            title={`Generate ${size} frame${size > 1 ? 's' : ''}`}
          >
            <Icon className="w-5 h-5" />
            <span className="text-xs">{label}</span>
          </button>
        ))}
      </div>
    </div>
  );
}