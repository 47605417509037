import { throttle } from '../utils/throttle';
import { enhanceWithGemini } from './gemini-ai';
import type { EnhancementSettings, EnhancementResponse } from '../types/enhancement';

async function enhancePromptBase(
  prompt: string,
  settings: EnhancementSettings
): Promise<EnhancementResponse> {
  try {
    const response = await enhanceWithGemini({ prompt, settings });
    
    // Ensure we're returning a clean, enhanced prompt
    return {
      enhancedPrompt: response.enhancedPrompt.trim(),
      suggestions: response.suggestions
    };
  } catch (error) {
    console.error('Prompt enhancement failed:', error);
    throw error instanceof Error ? error : new Error('Failed to enhance prompt');
  }
}

// Throttle to prevent too many requests
export const enhancePrompt = throttle(enhancePromptBase, 1000);