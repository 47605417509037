import React from 'react';
import { MessageSquare, Hash, Clock, ChevronLeft, ChevronRight } from 'lucide-react';
import type { TaskStatus } from '../lib/types';

interface ImageHistoryProps {
  generations: TaskStatus[];
}

export function ImageHistory({ generations }: ImageHistoryProps) {
  if (generations.length === 0) {
    return (
      <div className="bg-white dark:bg-rmz-gray rounded-xl p-3 border border-gray-200 dark:border-rmz-gray-light text-center transition-colors">
        <p className="text-gray-500 dark:text-gray-400 text-xs">No generations yet</p>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-rmz-gray rounded-xl border border-gray-200 dark:border-rmz-gray-light overflow-hidden transition-colors">
      <div className="p-2 border-b border-gray-200 dark:border-rmz-gray-light">
        <h2 className="font-medium text-xs text-gray-900 dark:text-white">History</h2>
      </div>
      <div className="divide-y divide-gray-200 dark:divide-rmz-gray-light max-h-[800px] overflow-y-auto">
        {generations.map((generation) => (
          <div
            key={generation.task_id}
            className="p-2 hover:bg-gray-50 dark:hover:bg-rmz-gray-light/50 transition-colors"
          >
            {generation.image_urls.map((imageUrl, index) => (
              <div key={`${generation.task_id}-${index}`} className="mb-2 last:mb-0">
                <div className="relative w-full h-0 pb-[100%] bg-gray-100 dark:bg-rmz-gray-light mb-1">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <img
                      src={imageUrl}
                      alt={generation.prompt}
                      className="max-w-full max-h-full w-auto h-auto object-contain"
                    />
                  </div>
                  {generation.image_urls.length > 1 && (
                    <div className="absolute bottom-1 left-1/2 -translate-x-1/2 px-2 py-0.5 bg-black/50 rounded-full text-white text-xs">
                      {index + 1}/{generation.image_urls.length}
                    </div>
                  )}
                </div>
              </div>
            ))}
            <p className="text-xs text-gray-700 dark:text-gray-300 mb-1 line-clamp-1">
              {generation.prompt}
            </p>
            <div className="flex items-center gap-2 text-xs text-gray-500 dark:text-gray-400">
              {generation.seed && (
                <div className="flex items-center gap-1">
                  <Hash className="w-3 h-3" />
                  <span>{generation.seed}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}