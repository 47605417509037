import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Instagram } from 'lucide-react';
import { rmzLogo } from '../lib/assets/logo';

const FEATURES = [
  {
    title: 'التصميم المعماري',
    subtitle: 'Architecture Design',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-architecturedesign.png?alt=media&token=efffc992-d4e2-436b-834c-58fec65b63bd',
  },
  {
    title: 'الإعلانات',
    subtitle: 'Advertising',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-advertisment-photography.png?alt=media&token=856cd289-aa13-4210-8fc6-74747b471d7d',
  },
  {
    title: 'تصوير المنتجات',
    subtitle: 'Product Photography',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-product-photography.png?alt=media&token=4a4f2d54-1a3b-4dca-9808-443e7768c53d',
  },
  {
    title: 'حدودك - خيالك',
    subtitle: 'and much more',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-and-more.png?alt=media&token=ec906839-5f24-4492-a513-ab3f680e07a8',
  },
  {
    title: 'التصميم الداخلي',
    subtitle: 'Interior Design',
    image: 'https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-int-design.png?alt=media&token=db29ffe3-8c78-4f65-ac58-3618c509aec6',
  }
];

export default function HomePage() {
  const navigate = useNavigate();

  const handleLaunch = () => {
    navigate('/login');
  };

  const handleDiscordClick = () => {
    window.open('https://discord.gg/gnq7yr25', '_blank');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a0b2e] via-[#1a0b2e] to-[#0f1955] overflow-hidden">
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 w-[300px] h-[300px] bg-[#FF3B8B] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute top-0 right-0 w-[300px] h-[300px] bg-[#00F5D4] rounded-full filter blur-[150px] opacity-20" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[300px] h-[300px] bg-[#B86EFF] rounded-full filter blur-[150px] opacity-20" />
      </div>

      <header className="relative container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <img src={rmzLogo} alt="RMZ.AI" className="h-32 w-auto" />
          <div className="flex items-center gap-6">
            <Link 
              to="/gallery" 
              className="px-6 py-2 text-cyan-300 hover:text-cyan-200 transition-colors rounded-full border border-cyan-300/20 hover:border-cyan-300/40 text-sm"
            >
              Gallery
            </Link>
            <button 
              onClick={handleLaunch}
              className="px-8 py-2 text-yellow-300 hover:text-yellow-200 transition-colors rounded-full border-2 border-yellow-300/30 bg-yellow-300/10 font-medium hover:bg-yellow-300/20 text-sm"
            >
              Launch rmz.ai
            </button>
            <button 
              onClick={handleDiscordClick}
              className="px-6 py-2 text-cyan-300 hover:text-cyan-200 transition-colors rounded-full border border-cyan-300/20 hover:border-cyan-300/40 text-sm"
            >
              Discord
            </button>
            <button 
              onClick={() => navigate('/contact')}
              className="px-6 py-2 text-cyan-300 hover:text-cyan-200 transition-colors rounded-full border border-cyan-300/20 hover:border-cyan-300/40 text-sm"
            >
              Contact
            </button>
            <a 
              href="https://instagram.com/rmz.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 text-cyan-300 hover:text-cyan-200 transition-colors"
            >
              <Instagram className="w-5 h-5" />
            </a>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <div className="relative container mx-auto px-4 pt-20 pb-32 text-center">
        <h1 className="text-8xl font-bold mb-16 text-white font-rubik tracking-wider leading-relaxed glow-text">
          خيالك . قصتك
        </h1>
        <div className="space-y-6 mb-20">
          <p className="text-2xl text-cyan-300 font-rubik">
            كل صورة تحكي قصتك
          </p>
          <p className="text-2xl text-gray-400">
            your imagination . your story
          </p>
          <p className="text-2xl text-cyan-400 font-rubik">
            مدعومة بأدوات رمز للذكاء الاصطناعي التوليدي
          </p>
          <p className="text-2xl text-gray-400">
            powered by rmz.ai Generative AI tools
          </p>
        </div>

        <div className="relative">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-bg-parts.png?alt=media&token=a8b2822d-e2c7-48e5-b10d-0008fdb98428"
            alt="Background Parts"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[100%] max-w-none opacity-30"
          />
          <button
            onClick={() => navigate('/beta')}
            className="relative transform hover:scale-105 transition-transform duration-300"
          >
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-beta-button.png?alt=media&token=b50907b0-612d-47e2-92a6-6d328e255c49"
              alt="Join RMZ.AI Beta"
              className="h-24 w-auto mx-auto"
            />
          </button>
        </div>
      </div>

      {/* App Preview */}
      <div className="relative container mx-auto px-4 mb-32">
        <div className="relative">
          {/* Outermost glow - very soft and wide */}
          <div className="absolute -inset-48 bg-gradient-to-r from-[#00F5D4]/5 via-[#B86EFF]/5 to-[#FF3B8B]/5 blur-[200px]"></div>
          
          {/* Outer glow */}
          <div className="absolute -inset-24 bg-gradient-to-r from-[#00F5D4]/10 via-[#B86EFF]/10 to-[#FF3B8B]/10 blur-[100px]"></div>
          
          {/* Middle glow */}
          <div className="absolute -inset-12 bg-gradient-to-r from-[#00F5D4]/15 via-[#B86EFF]/15 to-[#FF3B8B]/15 blur-[50px]"></div>
          
          {/* Inner glow */}
          <div className="absolute -inset-6 bg-gradient-to-r from-[#00F5D4]/20 via-[#B86EFF]/20 to-[#FF3B8B]/20 blur-[25px]"></div>
          
          {/* Image */}
          <img
            src="https://firebasestorage.googleapis.com/v0/b/rmz-ai.firebasestorage.app/o/Rmz.ai_resources%2Frmz.ai-main.png?alt=media&token=1dc2158d-ae0c-4227-b04b-aa7bdada2da4"
            alt="RMZ.AI Interface"
            className="relative w-full"
          />
        </div>
      </div>

      {/* Features Section */}
      <div className="relative container mx-auto px-4 py-32">
        <div className="text-center mb-32">
          <h2 className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-purple-400 font-rubik mb-8">
            استخدم رمز لهذا وأكثر
          </h2>
          <div className="mt-4 w-32 h-1 bg-gradient-to-r from-cyan-400 to-purple-400 mx-auto rounded-full"></div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 justify-items-center">
            {FEATURES.map((feature, index) => (
              <div
                key={index}
                className={`group relative transform hover:scale-105 transition-all duration-300 w-full max-w-md ${
                  index >= 3 ? 'lg:col-span-1.5' : ''
                }`}
              >
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full h-full object-cover rounded-2xl"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}