import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { app } from './config';
import { STORAGE_PATHS } from './collections';

const storage = getStorage(app);

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const MAX_CONCURRENT_UPLOADS = 3;

interface UploadOptions {
  contentType?: string;
  metadata?: Record<string, string>;
}

class Semaphore {
  private permits: number;
  private queue: Array<() => void> = [];

  constructor(permits: number) {
    this.permits = permits;
  }

  async acquire(): Promise<void> {
    if (this.permits > 0) {
      this.permits--;
      return Promise.resolve();
    }
    return new Promise<void>(resolve => {
      this.queue.push(resolve);
    });
  }

  release(): void {
    if (this.queue.length > 0) {
      const next = this.queue.shift();
      next?.();
    } else {
      this.permits++;
    }
  }
}

const uploadSemaphore = new Semaphore(MAX_CONCURRENT_UPLOADS);

async function fetchWithRetry(url: string, retries = MAX_RETRIES): Promise<Response> {
  let lastError: Error | null = null;

  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await fetch(url, { mode: 'cors' });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response;
    } catch (error) {
      lastError = error instanceof Error ? error : new Error('Failed to fetch');
      if (attempt < retries - 1) {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, attempt)));
      }
    }
  }

  throw lastError || new Error('Failed to fetch after multiple attempts');
}

async function uploadToStorage(
  path: string,
  blob: Blob,
  options: UploadOptions = {}
): Promise<string> {
  await uploadSemaphore.acquire();

  try {
    const storageRef = ref(storage, path);
    const metadata = {
      contentType: options.contentType || blob.type || 'image/jpeg',
      customMetadata: options.metadata
    };

    const snapshot = await uploadBytes(storageRef, blob, metadata);
    const downloadUrl = await getDownloadURL(snapshot.ref);

    if (!downloadUrl) {
      throw new Error('Failed to get download URL after upload');
    }

    return downloadUrl;
  } finally {
    uploadSemaphore.release();
  }
}

export async function uploadGeneratedImage(
  userId: string,
  imageUrl: string,
  taskId: string
): Promise<string> {
  try {
    if (!userId || !imageUrl || !taskId) {
      console.warn('Missing required parameters for image upload');
      return imageUrl;
    }

    // Download the image with retry mechanism
    const response = await fetchWithRetry(imageUrl);
    const blob = await response.blob();
    
    if (!blob.size) {
      console.warn('Retrieved empty image blob');
      return imageUrl;
    }

    // Create a unique filename with timestamp
    const timestamp = Date.now();
    const filename = `${taskId}_${timestamp}.jpg`;
    const imagePath = `${STORAGE_PATHS.USER_IMAGES}/${userId}/${filename}`;

    // Upload with metadata
    const downloadUrl = await uploadToStorage(imagePath, blob, {
      contentType: blob.type || 'image/jpeg',
      metadata: {
        originalUrl: imageUrl,
        uploadedAt: timestamp.toString(),
        userId,
        taskId
      }
    });

    return downloadUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    // Return original URL as fallback
    return imageUrl;
  }
}