import { GoogleGenerativeAI } from '@google/generative-ai';
import type { EnhancementRequest, EnhancementResponse } from '../types/enhancement';
import { extractTriggerWords, reinsertTriggerWords } from '../utils/trigger-words';

const API_KEY = import.meta.env.VITE_GOOGLE_AI_API_KEY;

export async function enhanceWithGemini(request: EnhancementRequest): Promise<EnhancementResponse> {
  try {
    const genAI = new GoogleGenerativeAI(API_KEY);
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

    // Extract trigger words before enhancement
    const { cleanPrompt, triggerWords } = extractTriggerWords(request.prompt);

    const prompt = `You are an expert at enhancing image generation prompts. Transform this prompt into a more detailed and visually rich description while maintaining its core meaning and intent.

Original prompt: "${cleanPrompt}"

Guidelines:
- Enhance visual details and composition
- Add lighting and atmospheric elements
- Incorporate artistic style elements
- Maintain the original subject and intent
- Keep the language natural and coherent
- Do not add any explanations or additional text, just return the enhanced prompt

Enhanced prompt:`;

    const result = await model.generateContent(prompt);
    const enhancedPrompt = result.response.text().trim();

    // Reinsert trigger words in their original positions
    const finalPrompt = reinsertTriggerWords(enhancedPrompt, triggerWords);

    return {
      enhancedPrompt: finalPrompt,
      suggestions: []
    };
  } catch (error) {
    console.error('Gemini API call failed:', error);
    throw new Error('Failed to enhance prompt with Gemini AI');
  }
}