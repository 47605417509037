import { z } from 'zod';

export const API_CONFIG = {
  BASE_URL: 'https://api.instasd.com/api_endpoints/o664i37e8yodua',
  API_KEY: 'TgDdtzkqQQLCzWfsnewROQ',
  INPUT_IDS: {
    SEED: '34b7bbd8a7f769d6',
    PROMPT: 'd49a264eda99feb3',
    ALMARAI: 'fffbb0bc60996104',
    NAJDI: 'a8cc0dfcbfe047ac',
    OMANI_ARCH: '936f24cc6bd4e9ca',
    WIDTH: '0aea311eb66e8f8e',
    HEIGHT: '96d5796891ee5398',
    STYLE_1: '39dfd9c865c9d548',
    STYLE_2: '0199944c9582e849',
    STYLE_3: '78359bdee0a2f07c',
    STYLE_4: 'f672ea76b2cf7ce7',
    BATCH_SIZE: '3993ea8334ab0eac'
  },
  RETRY: {
    MAX_ATTEMPTS: 3,
    INITIAL_DELAY: 1000,
    BACKOFF_FACTOR: 2,
    MAX_DELAY: 5000
  }
} as const;

export const taskResponseSchema = z.object({
  task_id: z.string(),
  status: z.string(),
  estimated_steps: z.number().optional(),
  completed_steps: z.number().optional(),
  image_urls: z.array(z.string()).optional(),
  video_urls: z.array(z.string()).optional(),
  error: z.string().optional(),
  delay_time: z.number().optional(),
  execution_time: z.number().optional(),
  cost: z.number().optional(),
  seed: z.number().optional(),
  prompt: z.string().optional()
});

export type TaskResponse = z.infer<typeof taskResponseSchema>;