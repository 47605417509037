import React, { useState } from 'react';
import { Check, ChevronDown } from 'lucide-react';
import type { StyleOptions } from '../lib/types';
import { STYLE_CATEGORIES, STYLE_OPTIONS, STYLE_VALUES } from '../lib/constants/styles';

interface StyleSelectorProps {
  styles: StyleOptions;
  onChange: (styles: StyleOptions) => void;
}

export function StyleSelector({ styles, onChange }: StyleSelectorProps) {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleStyle = (styleId: keyof StyleOptions, styleValue: string) => {
    const currentValue = styles[styleId];
    const newValue = currentValue === styleValue ? STYLE_VALUES.NONE : styleValue;
    
    onChange({
      ...styles,
      [styleId]: newValue
    });
  };

  const activeStylesCount = Object.values(styles).filter(style => style !== STYLE_VALUES.NONE).length;

  const filteredStyles = STYLE_OPTIONS.flatMap(category => 
    category.styles.filter(style => 
      style.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      style.value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white">Activate Magic</h3>
        <span className="text-sm text-gray-500 dark:text-gray-400">
          {activeStylesCount}/4 styles active
        </span>
      </div>

      {/* Search Bar */}
      <div className="relative">
        <input
          type="text"
          placeholder="Search styles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-4 py-2 bg-white/5 border border-gray-200 dark:border-rmz-gray-light rounded-lg focus:outline-none focus:ring-2 focus:ring-rmz-primary/50"
        />
      </div>

      {/* Category Selection */}
      <div className="grid grid-cols-2 gap-4">
        {Object.values(STYLE_CATEGORIES).map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
            className={`p-2 rounded-lg text-sm transition-colors ${
              selectedCategory === category
                ? 'bg-rmz-primary/20 text-rmz-primary'
                : 'bg-gray-100 dark:bg-rmz-gray-light text-gray-600 dark:text-gray-400 hover:bg-rmz-primary/10'
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Style Grid */}
      <div className="grid grid-cols-2 gap-4">
        {(searchQuery ? filteredStyles : STYLE_OPTIONS
          .find(cat => cat.category === selectedCategory)?.styles || [])
          .map(style => {
            const isActive = Object.values(styles).includes(style.value);
            const canActivate = isActive || activeStylesCount < 4;

            return (
              <button
                key={style.id}
                onClick={() => canActivate && toggleStyle(style.id as keyof StyleOptions, style.value)}
                disabled={!canActivate && !isActive}
                className={`relative rounded-xl overflow-hidden aspect-square group ${
                  isActive 
                    ? 'ring-2 ring-rmz-primary' 
                    : 'ring-1 ring-gray-200 dark:ring-rmz-gray-light hover:ring-rmz-primary/50'
                } ${!canActivate && !isActive ? 'opacity-50 cursor-not-allowed' : ''}`}
                title={style.description}
              >
                <img
                  src={style.image}
                  alt={style.title}
                  className={`w-full h-full object-cover transition-opacity duration-300 ${
                    isActive ? 'opacity-100' : 'opacity-50'
                  }`}
                />
                <div className={`absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent p-4 flex flex-col justify-between transition-opacity ${
                  isActive ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
                }`}>
                  <div className="self-end">
                    {isActive && (
                      <div className="bg-rmz-primary rounded-full p-1">
                        <Check className="w-4 h-4 text-rmz-dark" />
                      </div>
                    )}
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-white">
                      {style.title}
                    </h4>
                    <p className="text-xs text-gray-200">
                      {isActive ? 'Active' : canActivate ? 'Click to activate' : 'Max styles reached'}
                    </p>
                  </div>
                </div>
              </button>
            );
          })}
      </div>
    </div>
  );
}